import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, TwitterAuthProvider, OAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyCef-eWUFXXwsB161PlHYAnsOBHTEaJ2cM",
    authDomain: "artetechventures-623d5.firebaseapp.com",
    projectId: "artetechventures-623d5",
    storageBucket: "artetechventures-623d5.appspot.com",
    messagingSenderId: "106347923898",
    appId: "1:106347923898:web:01db4161a5f9f1023e3583",
    measurementId: "G-37FP5JFSEP"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app); 
const googleProvider = new GoogleAuthProvider();
const twitterProvider = new TwitterAuthProvider();
const linkedinProvider = new OAuthProvider('linkedin.com');
const microsoftProvider = new OAuthProvider('microsoft.com');
const db = getFirestore(app); 

export { auth, googleProvider, twitterProvider, linkedinProvider, microsoftProvider, db, storage };