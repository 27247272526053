import React, { useState } from "react";
import {
  auth,
  googleProvider,
  twitterProvider,
  linkedinProvider,
  microsoftProvider,
  db,
  doc,
  setDoc,
} from "./services/firebaseServices"; // Asume que esta es la ruta correcta
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import Swal from "sweetalert2";
import logo from "./logo.svg";
import "./App.css";
import googleIcon from "./assets/images/google-8.png";
import twitterIcon from "./assets/images/twittwr-8.png";
import linkedinIcon from "./assets/images/linkend-8.png";
import microsoftIcon from "./assets/images/microsoft-8.png";

function App() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [medicalSpeciality, setMedicalSpeciality] = useState("");
  const [studentStatus, setStudentStatus] = useState(true);
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSponsored, setIsSponsored] = useState("");
  const [sponsored, setSponsored] = useState("");
  const [errors, setErrors] = useState({});
  const [customSpeciality, setCustomSpeciality] = useState("");
  const [customLab, setCustomLab] = useState("");
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState(""); // Ciudad
  const [confirmAttendance, setConfirmAttendance] = useState(false); // Confirmar asistencia

  const labToSend = isSponsored === "Otro" ? customLab : isSponsored;
  const specialityToSend =
    medicalSpeciality === "Otro" ? customSpeciality : medicalSpeciality;

  const validateForm = () => {
    let tempErrors = {};

    if (!fullName) tempErrors.fullName = "El nombre completo es requerido.";
    if (!lastName) tempErrors.lastName = "El apellido es requerido.";
    if (!city) tempErrors.city = "La ciudad es requerida.";
    if (!documentNumber)
      tempErrors.documentNumber = "El número de documento es requerido.";
    if (!medicalSpeciality)
      tempErrors.medicalSpeciality = "La especialidad médica es requerida.";
    if (!address) tempErrors.address = "La dirección es requerida.";
    if (!email) tempErrors.email = "El email es requerido.";
    if (!phoneNumber)
      tempErrors.phoneNumber = "El número de teléfono es requerido.";
    if (!password) tempErrors.password = "La contraseña es requerida.";
    if (password !== confirmPassword)
      tempErrors.confirmPassword = "Las contraseñas no coinciden.";
    if (!confirmAttendance)
      tempErrors.confirmAttendance =
        "La confirmación de asistencia es requerida.";

    setErrors(tempErrors);
    return tempErrors;
  };

  const handleBlur = (field) => {
    let tempErrors = { ...errors };
    if (!field) {
      tempErrors[field] = `El campo ${field} es obligatorio.`;
    } else {
      delete tempErrors[field];
    }
    setErrors(tempErrors);
  };

  const arePasswordsEqual = () => {
    return password === confirmPassword;
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (!arePasswordsEqual()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Las contraseñas no coinciden.",
      }));
    } else {
      setErrors((prevErrors) => {
        const { confirmPassword, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  const saveUserProfile = async (uid) => {
    const userRef = doc(db, "userProfiles", uid);

    const userProfile = {
      fullName,
      lastName,
      documentNumber,
      medicalSpeciality: specialityToSend,
      studentStatus,
      address,
      phoneNumber,
      email,
      city, // Guardar ciudad
      confirmAttendance, // Guardar confirmación de asistencia
      isSponsored: sponsored === "Si" ? labToSend : "No está patrocinado",
      lab: sponsored === "Si" ? labToSend : "No está patrocinado",
    };

    await setDoc(userRef, userProfile);
  };

  const handleRegister = async () => {
    setLoading(true);
    const formErrors = validateForm();

    if (!arePasswordsEqual()) {
      Swal.fire("Error", "Las contraseñas no coinciden.", "error");
      setLoading(false);
      return;
    }

    if (Object.keys(formErrors).length === 0) {
      try {
        // Autenticar al usuario con Firebase Authentication
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const uid = userCredential.user.uid;

        // Aquí, el usuario ya está autenticado. Ahora, puedes guardar los detalles del usuario en Firestore.
        await saveUserProfile(uid);

        // Llamar a la función para enviar el correo
        const emailData = {
          email,
          password,
          fullName,
          lastName,
          documentNumber,
          medicalSpeciality: specialityToSend,
          studentStatus,
          address,
          phoneNumber,
          isSponsored: labToSend, // Aquí usamos labToSend
          lab: labToSend, // Y aquí también
        };
        await fetch(
          "https://us-central1-artetechventures-623d5.cloudfunctions.net/sendEmail",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(emailData),
          }
        );

        // Mostrar un mensaje de éxito
        Swal.fire(
          "¡Éxito!",
          "Registro exitoso. Revisa tu correo para más detalles.",
          "success"
        );
        resetForm();
      } catch (error) {
        // Mostrar un mensaje de error
        Swal.fire("Error", "Error al registrarse: " + error.message, "error");
      }
    }
    setLoading(false);
  };

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setFullName("");
    setLastName("");
    setDocumentNumber("");
    setMedicalSpeciality("");
    setAddress("");
    setPhoneNumber("");
    setIsSponsored("");
    setSponsored("");
    setCustomSpeciality("");
    setCustomLab("");
    setCity(""); // Reiniciar ciudad
    setConfirmAttendance(false); // Reiniciar confirmación de asistencia
  };

  return (
    <div className="App">
      <header className="App-header">
        {loading ? (
          <p>Creando su registro por favor, espere...</p>
        ) : (
          <>
            <img
              src="https://i.ibb.co/jkCdVpX/imegenlogo.png"
              className="App-logo"
              alt="logo"
            />
            <h2>Registro Día Mundial de la artritis</h2>

            <input
              type="text"
              placeholder="Nombre completo"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              onBlur={() => handleBlur("fullName")}
            />
            {errors.fullName && (
              <span style={{ color: "red" }}>{errors.fullName}</span>
            )}

            <input
              type="text"
              placeholder="Apellido"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              onBlur={() => handleBlur("lastName")}
            />
            {errors.lastName && (
              <span style={{ color: "red" }}>{errors.lastName}</span>
            )}

            <input
              type="text"
              placeholder="Número de documento"
              value={documentNumber}
              onChange={(e) => setDocumentNumber(e.target.value)}
              onBlur={() => handleBlur("documentNumber")}
            />
            {errors.documentNumber && (
              <span style={{ color: "red" }}>{errors.documentNumber}</span>
            )}

            <select
              value={medicalSpeciality}
              onChange={(e) => setMedicalSpeciality(e.target.value)}
              onBlur={() => handleBlur("medicalSpeciality")}
            >
              <option value="">Selecciona una especialidad</option>
              <option value="Estudiante de Medicina">
                Estudiante de Medicina
              </option>
              <option value="Reumatólogo">Reumatólogo</option>
              <option value="Ortopedista">Ortopedista</option>
              <option value="Internista">Internista</option>
              <option value="Enfermero">Enfermero</option>
              <option value="Fisioterapeuta">Fisioterapeuta</option>
              <option value="Terapeuta ocupacional">
                Terapeuta ocupacional
              </option>
              <option value="Podólogo">Podólogo</option>
              <option value="Psicólogo">Psicólogo</option>
              <option value="Nutricionista">Nutricionista</option>
              <option value="Estudiante de Medicina">
                Estudiante de Medicina
              </option>
              <option value="Residente de Reumatología">
                Residente de Reumatología
              </option>
              <option value="Residente de Ortopedia">
                Residente de Ortopedia
              </option>
              <option value="Residente de Medicina Interna">
                Residente de Medicina Interna
              </option>
              <option value="Investigador en Reumatología">
                Investigador en Reumatología
              </option>
              <option value="Educador en salud">Educador en salud</option>
              <option value="Otro">Otro</option>
            </select>
            {medicalSpeciality === "Otro" && (
              <input
                type="text"
                placeholder="Escribe tu especialidad"
                value={customSpeciality}
                onChange={(e) => setCustomSpeciality(e.target.value)}
              />
            )}
            {errors.medicalSpeciality && (
              <span style={{ color: "red" }}>{errors.medicalSpeciality}</span>
            )}

            <input
              type="text"
              placeholder="Dirección"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              onBlur={() => handleBlur("address")}
            />
            {errors.address && (
              <span style={{ color: "red" }}>{errors.address}</span>
            )}
            {/* Campo Ciudad */}
            <input
              type="text"
              placeholder="Ciudad"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              onBlur={() => handleBlur("city")}
            />
            {errors.city && <span style={{ color: "red" }}>{errors.city}</span>}

            {/* Campo Confirmar Asistencia */}
            <div className={errors.confirmAttendance ? "errorHighlight" : ""}>
              <label>
                <input
                  type="radio"
                  value="Si"
                  checked={confirmAttendance === "Si"}
                  onChange={(e) => setConfirmAttendance(e.target.value)}
                />
                Sí
              </label>
              <label>
                <input
                  type="radio"
                  value="No"
                  checked={confirmAttendance === "No"}
                  onChange={(e) => setConfirmAttendance(e.target.value)}
                />
                No
              </label><br/>
                 Confirmar asistencia presencial
              {errors.confirmAttendance && (
                <span>{errors.confirmAttendance}</span>
              )}
            </div>

            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={() => handleBlur("email")}
            />
            {errors.email && (
              <span style={{ color: "red" }}>{errors.email}</span>
            )}

            <input
              type="text"
              placeholder="Número de teléfono"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              onBlur={() => handleBlur("phoneNumber")}
            />
            {errors.phoneNumber && (
              <span style={{ color: "red" }}>{errors.phoneNumber}</span>
            )}
            <select
              value={sponsored}
              onChange={(e) => setSponsored(e.target.value)}
              onBlur={() => handleBlur("sponsored")}
            >
              <option value="">¿Estás patrocinado?</option>
              <option value="Si">Sí</option>
              <option value="No">No</option>
            </select>

            {sponsored === "Si" && (
              <>
                <select
                  value={isSponsored}
                  onChange={(e) => setIsSponsored(e.target.value)}
                  onBlur={() => handleBlur("isSponsored")}
                >
                  <option value="">Selecciona un laboratorio</option>
                  <option value="Pfizer">Pfizer</option>
                  <option value="Roche">Roche</option>
                  <option value="Novartis">Novartis</option>
                  <option value="Merck">Merck & Co.</option>
                  <option value="GlaxoSmithKline">GlaxoSmithKline</option>
                  <option value="Johnson & Johnson">Johnson & Johnson</option>
                  <option value="Sanofi">Sanofi</option>
                  <option value="Abbott Laboratories">
                    Abbott Laboratories
                  </option>
                  <option value="AstraZeneca">AstraZeneca</option>
                  <option value="Bayer">Bayer</option>
                  <option value="Eli Lilly">Eli Lilly</option>
                  <option value="Bristol-Myers Squibb">
                    Bristol-Myers Squibb
                  </option>
                  <option value="Takeda Pharmaceuticals">
                    Takeda Pharmaceuticals
                  </option>
                  <option value="Boehringer Ingelheim">
                    Boehringer Ingelheim
                  </option>
                  <option value="Amgen">Amgen</option>
                  <option value="Gilead Sciences">Gilead Sciences</option>
                  <option value="Biogen">Biogen</option>
                  <option value="Celgene">Celgene</option>
                  <option value="Teva Pharmaceutical Industries">
                    Teva Pharmaceutical Industries
                  </option>
                  <option value="Regeneron Pharmaceuticals">
                    Regeneron Pharmaceuticals
                  </option>
                  <option value="Otro">Otro</option>
                </select>
                {isSponsored === "Otro" && (
                  <input
                    type="text"
                    placeholder="Escribe el nombre del laboratorio"
                    value={customLab}
                    onChange={(e) => setCustomLab(e.target.value)}
                  />
                )}
                {errors.isSponsored && (
                  <span style={{ color: "red" }}>{errors.isSponsored}</span>
                )}
              </>
            )}

            <hr />

            <input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {errors.password && (
              <span style={{ color: "red" }}>{errors.password}</span>
            )}
            <input type="hidden" value={studentStatus} />

            <input
              type="password"
              placeholder="Confirmar Contraseña"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              // onChange={handleConfirmPasswordChange}
            />
            {errors.confirmPassword && (
              <span style={{ color: "red" }}>{errors.confirmPassword}</span>
            )}

            <button
              onClick={handleRegister}
              disabled={Object.keys(errors).length > 0}
            >
              Regístrate
            </button>
          </>
        )}
      </header>
    </div>
  );
}

export default App;
